/* styles.css */
.green {
    background-color: #88EE88;
}

.red {
    background-color: #FF6868;
}

.orange {
    background-color: orange;
}
