.sidebar {
    position: fixed;  /* Fixed position */
    top: 0;          /* Position from the top */
    left: 0;         /* Position from the left */
    height: 100%;    /* Take full height of the viewport */
    width: 100px; 
    overflow-y: auto; 
    background: #244999 0% 0% no-repeat padding-box;
opacity: 1; /* Enable vertical scrolling if content exceeds sidebar height */
  }
  
  /* Style for sidebar items (optional) */
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul li {
    padding: 10px;
    cursor: pointer;
  }
  
  /* Style for active link (optional) */
  .sidebar ul li.active {
    background-color: #ccc;
  }
  .sidebar ul li.fixed-item {
    bottom: 0;
    width: 100%;
    left: 0;
    position: absolute;
  
}
  