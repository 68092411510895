.table {
    border-collapse: collapse; /* Remove spacing between table cells */
  }
  
  /* Style for table header */
  .table th {
    border: none; /* Remove borders for table headers */
    
    font-weight:500; 
    color: #393939;/* Add padding for better spacing */
  }
  
  /* Style for table data */
  .table td {
    border: 1px solid #D6D6D6; /* Add borders for table data */
    padding: 10px;
    font-size: 14px;
    font-weight:bold; /* Add padding for better spacing */
  }
  .col1{
    width: 10px;
  }
  
  